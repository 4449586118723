import * as React from "react";
import LayoutPatient from "components/layout/patient";
import HeaderLarge from "components/section/headerLarge";

const IndexPage = () => {
  return (
    <LayoutPatient>
      <HeaderLarge
        title="Een vraag stellen"
        description="Hier is ruimte voor een beschrijvende tekst of introductie"
      />
    </LayoutPatient>
  );
};

export default IndexPage;
